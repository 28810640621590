var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-1",modifiers:{"sidebar-1":true}}],attrs:{"variant":"outline-danger"}},[_c('b-icon',{attrs:{"icon":"list"}})],1),_c('b-sidebar',{attrs:{"id":"sidebar-1","title":"Menu","shadow":""}},[_c('b-nav',{staticClass:"mt-3 px-2",attrs:{"pills":"","vertical":""}},[_c('router-link',{attrs:{"to":{ name: _vm.$routesNames.newOrder },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var navigate = ref.navigate;
var href = ref.href;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{class:[
              isExactActive && 'router-link-exact-active'
                ? 'active bg-danger text-white'
                : 'text-danger',
              'text-decoration-none',
              'nav-link'
            ],attrs:{"href":href},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('b-icon',{staticClass:"mx-2",attrs:{"icon":"house-door"}}),_vm._v(" "+_vm._s(_vm.$t("general.menu.newOrder"))+" ")],1)])]}}])}),_c('router-link',{attrs:{"to":{ name: _vm.$routesNames.orders },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
            var href = ref.href;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{class:[
              isExactActive && 'router-link-exact-active'
                ? 'active bg-danger text-white'
                : 'text-danger',
              'text-decoration-none',
              'nav-link'
            ],attrs:{"href":href},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('b-icon',{staticClass:"mx-2",attrs:{"icon":"house-door"}}),_vm._v(" "+_vm._s(_vm.$t("general.menu.orders"))+" ")],1)])]}}])}),_c('router-link',{attrs:{"to":{ name: _vm.$routesNames.pickOrders },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
            var href = ref.href;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"nav-item"},[_c('a',{class:[
              isExactActive && 'router-link-exact-active'
                ? 'active bg-danger text-white'
                : 'text-danger',
              'text-decoration-none',
              'nav-link'
            ],attrs:{"href":href},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_c('b-icon',{staticClass:"mx-2",attrs:{"icon":"house-door"}}),_vm._v(" "+_vm._s(_vm.$t("general.menu.pickOrders"))+" ")],1)])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }