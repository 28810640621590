<script>
import { Vue, Component } from "vue-property-decorator";
import HeaderComponent from "@/components/layouts/Header.vue";
import Content from "@/components/layouts/Content.vue";

@Component({ components: { HeaderComponent, Content } })
export default class Home extends Vue {}
</script>

<template>
  <div>
    <header-component></header-component>
    <Content></Content>
  </div>
</template>
